import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  HeaderContentBlock,
  FormContentBlock,
  DsForm,
  DsFormQuestionTypes,
  DsFormQuestion,
  DsFormAnswer,
  DsFormEntry,
  DsFormAnswers,
} from '@ds/interfaces';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { FormEntryService } from '@ds/api-services';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-sc',
  template: `
    <div class="ds-row-sc">
      <ds-cb-header [text]="_form.title" textSize="large"></ds-cb-header>
    </div>
    <form
      *ngIf="!showThankyou; else thankYou"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit(model)"
    >
      <formly-form
        [form]="formGroup"
        [fields]="fields"
        [model]="model"
      ></formly-form>
      <div class="error-messages" *ngIf="formGroup.invalid">
        One more required fields are missing or invalid.
      </div>
      <div class="action-buttons">
        <button type="submit" class="ds-button" [disabled]="formIsDisabled">
          {{ _form.publicConfig.submitButtonText }}
        </button>
      </div>
    </form>
    <ng-template #thankYou>
      <ds-cb-html [html]="thankYouContent"></ds-cb-html>
    </ng-template>
    <pre *ngIf="false">{{ model | json }}</pre>
  `,
  // most styles are in _layout.scss
  styles: [
    `
      :host {
        display: block;
      }
      form {
        background-color: white;
        padding: 25px 0;
        border-radius: 25px;
        border: 1px solid lightgray;
      }
      .action-buttons {
        text-align: center;
      }
      .error-messages {
        padding: 25px;
        text-align: center;
        font-weight: bold;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFormComponent {
  @Input() set form(val: DsForm) {
    this._form = val;
    this.fields = val.questions.map(generateFormlyField);
  }

  isSubmitting = false;
  showThankyou = false;

  get form(): DsForm {
    return this._form;
  }

  _form: DsForm = {};

  formGroup = new FormGroup({});

  fields: FormlyFieldConfig[] = [];

  model: DsFormAnswers = {};

  _block: FormContentBlock;
  @Input()
  set block(val: FormContentBlock) {
    this.init(val);
  }

  constructor(
    private formEntryService: FormEntryService,
    private cd: ChangeDetectorRef
  ) {}

  init(block: FormContentBlock) {
    console.log('block type form!!!!');
    this._block = block;
    this.form = block.form;
  }

  onSubmit(answers: DsFormAnswers) {
    this.isSubmitting = true;

    const entry: DsFormEntry = {
      formId: this._block.formId,
      answers: answers,
      questions: this._form.questions,
      meta: {
        referrer: window.location.toString(),
      },
    };
    this.formEntryService
      .create(entry)
      .pipe(
        catchError((err) => {
          this.isSubmitting = false;
          return err;
        })
      )
      .subscribe((resp) => {
        if (this.form.publicConfig.postSubmitAction === 'redirect') {
          console.log('do redirect');
        } else {
          this.showThankyou = true;
        }
        this.cd.markForCheck();
      });
  }

  get thankYouContent() {
    return `<p>${this.form.publicConfig.thankYouContent}</p>`;
  }

  get formIsDisabled() {
    return this.isSubmitting || this.formGroup.invalid;
  }
}

function listToOptions(list: string[]) {
  return list.map((i) => ({ label: i, value: i }));
}

function generateFormlyField(q: DsFormQuestion): FormlyFieldConfig {
  switch (q.type) {
    case DsFormQuestionTypes.SIMPLE_TEXT:
      return {
        template: `<p class="ds-form-simple-text">${q.simpleText}</p>`,
      };
    case DsFormQuestionTypes.FULL_NAME:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        fieldGroup: [
          {
            template: `<label>${q.prompt} ${q.isRequired ? '*' : ''}</label>`,
          },
          {
            fieldGroupClassName: 'ds-row',
            key: q.id,
            templateOptions: {
              label: q.prompt,
            },
            fieldGroup: [
              {
                className: `ds-col`,
                type: 'input',
                key: 'fullName.firstName',
                templateOptions: {
                  placeholder: 'First Name',
                  required: q.isRequired,
                },
              },
              {
                className: 'ds-col',
                type: 'input',
                key: 'fullName.middleName',
                hide: !q.config || !q.config.fullNameMiddle,
                templateOptions: {
                  placeholder: 'Middle Name',
                  required: false,
                },
              },
              {
                className: 'ds-col',
                type: 'input',
                key: 'fullName.lastName',
                templateOptions: {
                  placeholder: 'Last Name',
                  required: q.isRequired,
                },
              },
            ],
          },
        ],
      };
    case DsFormQuestionTypes.EMAIL_ADDRESS:
      return {
        key: `${q.id}.text`,
        type: 'input',
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        templateOptions: {
          type: 'email',
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.DATE:
      return {
        key: `${q.id}.text`,
        type: 'input',
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        templateOptions: {
          type: 'date',
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.SENSITIVE:
      return {
        key: `${q.id}.text`,
        type: 'password',
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        templateOptions: {
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.SHORT_ANSWER:
    case DsFormQuestionTypes.PHONE_NUMBER:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        key: `${q.id}.text`,
        type: 'input',
        templateOptions: {
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.ADDRESS:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        fieldGroupClassName: 'ds-field-group',
        fieldGroup: [
          {
            template: `<label>${q.prompt} ${q.isRequired ? '*' : ''}</label>`,
          },
          {
            fieldGroupClassName: 'ds-row',
            key: q.id,
            templateOptions: {
              label: q.prompt,
            },
            fieldGroup: [
              {
                className: 'ds-col-12',
                type: 'input',
                key: 'address.lineOne',
                templateOptions: {
                  placeholder: 'Line 1',
                  required: q.isRequired,
                },
              },
              {
                className: 'ds-col-12',
                type: 'input',
                key: 'address.lineTwo',
                templateOptions: {
                  placeholder: 'Line 2',
                },
              },
              {
                className: 'ds-col-6',
                type: 'input',
                key: 'address.city',
                templateOptions: {
                  placeholder: 'City',
                  required: q.isRequired,
                },
              },
              {
                className: 'ds-col-6',
                type: 'input',
                key: 'address.state',
                templateOptions: {
                  placeholder: 'state',
                  required: q.isRequired,
                },
              },
              {
                className: 'ds-col-6',
                type: 'input',
                key: 'address.postalCode',
                templateOptions: {
                  placeholder: 'Postal Code',
                  required: q.isRequired,
                },
              },
              {
                className: 'ds-col-6',
                type: 'input',
                key: 'address.country',
                templateOptions: {
                  placeholder: 'Country',
                  required: q.isRequired,
                },
              },
            ],
          },
        ],
      };
    case DsFormQuestionTypes.DROPDOWN:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        key: `${q.id}.text`,
        type: 'select',
        templateOptions: {
          options: [
            { label: 'Select One', value: null },
            ...listToOptions(q.options),
          ],
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.HEADER:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        template: `<h3 class="fluid-text">${q.headerText}</h3>`,
      };
    case DsFormQuestionTypes.SINGLE_OPTION:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        key: `${q.id}.text`,
        type: 'radio',
        templateOptions: {
          options: listToOptions(q.options),
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.LONG_ANSWER:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        key: `${q.id}.text`,
        type: 'textarea',
        templateOptions: {
          rows: 8,
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.FILE:
      return {
        className: q.size ? `ds-col ds-col-sm-${q.size}` : 'ds-col',
        key: `${q.id}.files`,
        type: 'file-upload',
        templateOptions: {
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.SIGNATURE:
      return {
        className: 'ds-col',
        key: `${q.id}.image`,
        type: 'signature',
        templateOptions: {
          signatureText: q.simpleText,
          label: q.prompt,
          required: q.isRequired,
        },
      };
    case DsFormQuestionTypes.HIDDEN:
    default:
      console.warn('[CB Form]: Not Recognized', q.type);
      return {};
      break;
  }
}
